import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../components/layout/layout'
import { Seo } from '../../components/seo/default'

export default function Page({
    pageContext: {
        title,
        sections
    }
}) {

    return (
        <React.Fragment>
            <Layout>
                <Seo title={`${title}`} />
                <div className="w-full py-4 px-2 sm:py-8 sm:px-8 max-w-7xl mx-auto" id="cols_2-page-template">
                    <h1 className='mb-4 pb-2 uppercase text-base sm:text-xl font-bold'>{title}</h1>

                    <div className="sections grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
                        {
                            sections.map((item, i) => (
                                <div className='section mb-4' key={i}>
                                    <Link to={item.link} className="block w-full">
                                        <div className="item w-full border border-gray-300">
                                            <div className="item-image w-full">
                                                <img src={item.image} alt={item.title} className="w-full h-full" />
                                            </div>
                                            <div className="py-4 px-2">
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}